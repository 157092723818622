import $ from "jquery";
import "bootstrap";
import "../node_modules/bootstrap/js/dist/tooltip.js";
import "metismenu";
import "jquery-slimscroll";
import "node-waves";
import "jquery-validation";
import "jquery-validation-unobtrusive";
import "./Utilities/dogfish.ui.validation";
import { StorageHelper } from "./Utilities/ClientStorageUtilities";
import { BooleanUtilities } from "./Utilities/Utilities";
import { Subscriptions } from "./Utilities/SubscriptionsUtilities";
import { ChangeUserPasswordModal } from "./Pages/Shared/ChangeUserPasswordModal";
import { MyAccountModal } from "./Pages/Shared/MyAccountModal";

const navigationCollapsedKey = "NavigationCollapsed";
let $changeUserPasswordModal: ChangeUserPasswordModal;
let $myAccountModal: MyAccountModal;

function initTooltip() {
    $('[data-toggle="tooltip"]').tooltip();
}

//function initCustomModal() {
//    $('[data-plugin="custommodal"]').on('click', function (e) {
//        Custombox.open({
//            target: $(this).attr("href"),
//            effect: $(this).attr("data-animation"),
//            overlaySpeed: $(this).attr("data-overlaySpeed"),
//            overlayColor: $(this).attr("data-overlayColor")
//        });
//        e.preventDefault();
//    });
//}
//function initRangeSlider() {
//    $('[data-plugin="range-slider"]').slider({});
//}
//function initSwitchery() {
//    $('[data-plugin="switchery"]').each(function (idx, obj) {
//        new Switchery($(this)[0], $(this).data());
//    });
//}
//function initKnob() {
//    $('[data-plugin="knob"]').each(function (idx, obj) {
//        $(this).knob();
//    });
//}


function initSlimscroll() {
    //$('.slimscroll').slimScroll({ height: 'auto', position: 'right', size: "8px", color: '#9ea5ab' });
    //$(".slimscroll-alt").slimScroll({ position: 'right', size: "5px", color: '#98a6ad', wheelStep: 10 });
}

function initActiveMenu() {
    // === following js will activate the menu in left side bar based on url ====
    $("#sidebar-menu a").each(function () {
        var pageUrl = window.location.href.split(/[?#]/)[0];
        var $this = $((this) as any);
        var linkUrl = $this.attr('href') || '';
        if (linkUrl == pageUrl || pageUrl.endsWith(linkUrl)) {
            $this.addClass("active");
            $this.parent().addClass("active"); // add active to li of the current link
            $this.parent().parent().addClass("in");
            $this.parent().parent().prev().addClass("active"); // add active class to an anchor
            $this.parent().parent().parent().addClass("active");
            $this.parent().parent().parent().parent().addClass("in"); // add active to li of the current link
            $this.parent().parent().parent().parent().parent().addClass("active");
        }
    });
}

function getChangeUserPasswordModal(): ChangeUserPasswordModal {
    if ($changeUserPasswordModal == undefined) {
        $changeUserPasswordModal = new ChangeUserPasswordModal($('div.modal#modalChangeUserPassword'), false);
    }
    return $changeUserPasswordModal;
}

function getMyAccountModal(): MyAccountModal {
    if ($myAccountModal == undefined) {
        $myAccountModal = new MyAccountModal($('div.modal#modalMyAccount'), false);
    }
    return $myAccountModal;
}

function initAccountOperations() {
    var $pwdLink = $("#changePasswordLink");
    $pwdLink.click(() => {
        getChangeUserPasswordModal().showFor();
    });

    var $myAccountLink = $("#myAccountLink");
    $myAccountLink.click(() => {
        getMyAccountModal().showFor();
    });
}

function initMenu() {
    $("#side-menu").metisMenu();

    var localStorageHelper = new StorageHelper.LocalStorageHelper();
    var isCollapsed = BooleanUtilities.getBool(localStorageHelper.get(navigationCollapsedKey));

    // Add collapse button
    $('.button-menu-mobile').on('click', event => {
        event.preventDefault();
        var shouldCollapse = !$('body').hasClass('enlarged');
        localStorageHelper.add(navigationCollapsedKey, shouldCollapse.toString());
        $('body').toggleClass('enlarged');
    });

    var windowWidth = $(window).width();
    if (isCollapsed || (windowWidth != undefined && windowWidth < 1025)) {
        $('body').addClass('enlarged');
    } else {
        $('body').removeClass('enlarged');
    }
}

function init() {
    initTooltip();
    initSlimscroll();
    initMenu();
    initActiveMenu();
    Subscriptions.subscribeToBrowserNotifications();
    initAccountOperations();
}

$(document.body).ready(() => {
    init();
});