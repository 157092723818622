import { FormModal } from "../../Utilities/Modal/FormModal";
import { Dialog } from "../../Utilities/DialogUtilities";
import { DogfishUtilities } from "../../Utilities/DogfishUtilities";
import { PhotoIdEditor } from "./PhotoIdEditor";

export class MyAccountModal extends FormModal {
    private readonly loadUrl: string;
    private photoIdEditor?: PhotoIdEditor;

    constructor($modal: JQuery<HTMLElement>, enableLogging: boolean) {
        super($modal, enableLogging, true, true);
        this.loadUrl = $modal.data('geturl') as string;
        this.applyEvents();
    }

    private applyEvents() {
        this.photoIdEditor = new PhotoIdEditor(this.$form);
    }

    protected postReload() {
        super.postReload();
        this.applyEvents();
    }

    showFor(): Promise<void> {
        var loadDataUrl = this.loadUrl;
        //if (activityLogId !== undefined)
        //    loadDataUrl += '/' + activityLogId;

        return super.show(loadDataUrl);
    }

    onSuccess(data: any): void {
        Dialog.success('Your account has been updated.');
        this.hide();
        DogfishUtilities.refreshPhotoId();
    }

    protected save(): Promise<any> {
        if (this.photoIdEditor !== undefined) {
            return this.photoIdEditor.saveEdit().then(() => super.save());
        }

        return super.save();
    }
}