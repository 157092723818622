import { FormModal } from "../../Utilities/Modal/FormModal";
import { Dialog } from "../../Utilities/DialogUtilities";
import { PasswordStrength } from "../../Utilities/PasswordStrengthUtilities";

export class ChangeUserPasswordModal extends FormModal {
    constructor($modal: JQuery<HTMLElement>, enableLogging: boolean) {
		super($modal, enableLogging);
		PasswordStrength.initPasswordStength($('#modalChangeUserPassword_Password'));
    }

    showFor(): void {
        this.$form.find('input:password').val('');
        super.show();
    }

    onSuccess(data: any): void {
        Dialog.success('Your password has been updated.');
        this.hide();
	}
}