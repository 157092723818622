import $ from "jquery";
import "jquery-validation";
import "jquery-validation-unobtrusive";

$.validator.addMethod("requiredif", function (currentValue, element, params) {
    if (element.nodeName.toLowerCase() === "select") {
        // could be an array for select-multiple or a string, both are fine this way
        const val = $(element).val() as string;
        if (val && val.length > 0) {
            return true;
        }
    }
    if (checkable(element) && getLength(currentValue, element) > 0) {
        return true;
    }

    // Do not validate if value passed
    if ($.trim(currentValue).length > 0) {
        return true;
    }

    var $other: JQuery<HTMLElement> = $('#' + params.other);
    var otherType = $other.attr('type') || "";
    var otherVal = otherType.toUpperCase() === 'CHECKBOX' ? ($other.is(':checked') ? 'true' : 'false') : ($other.val() as string).toLowerCase();

    // Decide if we need to enforce required on element
    var checkRequired = params.comp === 'isequalto' ? otherVal === params.value : otherVal !== params.value;

    // Comparison not met so field is valid
    if (!checkRequired) {
        return true;
    }

    // Value is empty to get this far - field invalid
    return false;
});

$.validator.unobtrusive.adapters.add("requiredif", ["other", "comp", "value"],
    function (options: any) {
        options.rules['requiredif'] = {
            other: options.params.other,
            comp: options.params.comp,
            value: options.params.value
        };
        options.messages['requiredif'] = options.message;
    }
);

$.validator.addMethod('numericlessthan', function (value, element, params) {
    const otherValue = $(params.element).val() as string;
    const floatOtherValue = parseFloat(otherValue);
    const floatValue = parseFloat(value);

    return isNaN(floatValue) && isNaN(floatOtherValue)
        || params.allowempty === 'True' && value === ''
        || (params.allowequality === 'True'
            ? floatValue <= floatOtherValue
            : floatValue < floatOtherValue);
}, '');

$.validator.unobtrusive.adapters.add('numericlessthan', ['other', 'allowequality', 'allowempty'], function (options: any) {
    var prefix = options.element.name.substr(0, options.element.name.lastIndexOf('.') + 1),
        other = options.params.other,
        fullOtherName = appendModelPrefix(other, prefix),
        element = $(options.form).find(':input[name=' + fullOtherName + ']')[0];

    options.rules['numericlessthan'] = { allowequality: options.params.allowequality, allowempty: options.params.allowempty, element: element };
    if (options.message) {
        options.messages['numericlessthan'] = options.message;
    }
});

$.validator.addMethod('numericgreaterthan', function (value, element, params) {
    const otherValue = $(params.element).val() as string;
    const floatOtherValue = parseFloat(otherValue);
    const floatValue = parseFloat(value);

    return isNaN(floatValue) && isNaN(floatOtherValue)
        || params.allowempty === 'True' && value === ''
        || (params.allowequality === 'True'
            ? floatValue >= floatOtherValue
            : floatValue > floatOtherValue);
}, '');

$.validator.unobtrusive.adapters.add('numericgreaterthan', ['other', 'allowequality', 'allowempty'], function (options: any) {
    var prefix = options.element.name.substr(0, options.element.name.lastIndexOf('.') + 1),
        other = options.params.other,
        fullOtherName = appendModelPrefix(other, prefix),
        element = $(options.form).find(':input[name=' + fullOtherName + ']')[0];

    options.rules['numericgreaterthan'] = { allowequality: options.params.allowequality, allowempty: options.params.allowempty, element: element };
    if (options.message) {
        options.messages['numericgreaterthan'] = options.message;
    }
});


function appendModelPrefix(value: string, prefix: string) {
    if (value.indexOf('*.') === 0) {
        value = value.replace('*.', prefix);
    }
    return value;
}




function checkable(element: HTMLElement): boolean {
    return $(element).is(':checkbox,:radio');
};

function findByName(formElement: JQuery<HTMLFormElement>, name: string | null): JQuery<HTMLElement> | undefined {
    if (name == null)
        return undefined;

    return $(formElement).find("[name='" + escapeCssMeta(name) + "']");
};

function getLength(value: any, element: HTMLElement) {
    switch (element.nodeName.toLowerCase()) {
    case "select":
        return $("option:selected", element).length;
    case "input":
        if (checkable(element)) {
            var $form = $(element).closest('form') as JQuery<HTMLFormElement>;
            var foundElement = findByName($form, element.localName);
            return foundElement != undefined ? foundElement.filter(":checked").length : value.length;
        }
    }
    return value.length;
};

function escapeCssMeta(text: string) {
    return text.replace(/([\\!"#$%&'()*+,./:;<=>?@\[\]^`{|}~])/g, "\\$1");
};



    //$.validator.addMethod("requiredwhenotherempty", function (value, element, params) {
    //    var otherProp = $('#' + params);

    //    if (value === "" && otherProp.val() === "") {
    //        return false;
    //    }

    //    return true;
    //});
    //$.validator.unobtrusive.adapters.addSingleVal("requiredwhenotherempty", "otherproperty");

    

    // May need the following to get this working with complex (nested) objects however it hasn't been tested
    //function getModelPrefix(fieldName) {
    //    return fieldName.substr(0, fieldName.lastIndexOf(".") + 1).replace(".", "_");
    //}